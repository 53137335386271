$(window).load(function() {
    // Animate loader off screen
    $(".loading").fadeOut("slow");
    $(".loaded").fadeIn("slow");
});
$(document).ready(function() {
    setTimeout(function() {
        $("html, body").animate({
            scrollTop: 0
        }, '500', 'swing', function() {
            //alert("Finished animating");
        });
    }, 1500);
});